:root{
  --qm-layout-width: 1200px;
  --qm-border-color: #eee;
  --qm-color-primary: #bf372f;
  --qm-color-success: #00b578;
  --qm-color-danger: #ff3141;
  --qm-color-warning: #ff8f1f;
  --qm-color-info: #0c6ad4;
  --qm-layer-fill-1: #f2f3f5;
  --qm-color-box: #f5f5f5;
  --qm-font-size-1: 12px;
  --qm-font-size-2: 14px;
  --qm-font-size-3: 16px;
  --qm-font-size-4: 18px;
  --qm-color-text: #333;
  --qm-color-text-secondary: #666;
  --qm-radius-s: 4px;
  --qm-radius-m: 8px;
  --qm-radius-l: 12px;
}

html,
body {
  min-width: var(--qm-layout-width);
  line-height: 1;
}

body,
ul,
ol,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
form,
fieldset,
legend,
input,
textarea,
button,
p,
blockquote,
th,
td,
pre,
xmp {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

caption,
th {
  text-align: inherit;
}

ul,
ol,
menu {
  list-style: none;
}

fieldset,
img {
  border: 0;
}

img,
object,
input,
textarea,
button,
select {
  vertical-align: middle;
}

article,
aside,
footer,
header,
section,
nav,
figure,
figcaption,
hgroup,
details,
menu {
  display: block;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "\0020";
}

textarea {
  overflow: auto;
  resize: vertical;
}

input,
textarea,
button,
select,
a {
  outline: 0 none;
  border: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
ins,
s,
u,
del {
  text-decoration: none;
}

sup,
sub {
  vertical-align: baseline;
}

svg:not(:root) {
  overflow: hidden;
}

a {
  text-decoration: none;
}

input::-ms-clear,
input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */
