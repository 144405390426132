/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

:root{
  --qm-layout-width: 1200px;
  --qm-border-color: #eee;
  --qm-color-primary: #bf372f;
  --qm-color-success: #00b578;
  --qm-color-danger: #ff3141;
  --qm-color-warning: #ff8f1f;
  --qm-color-info: #0c6ad4;
  --qm-layer-fill-1: #f2f3f5;
  --qm-color-box: #f5f5f5;
  --qm-font-size-1: 12px;
  --qm-font-size-2: 14px;
  --qm-font-size-3: 16px;
  --qm-font-size-4: 18px;
  --qm-color-text: #333;
  --qm-color-text-secondary: #666;
  --qm-radius-s: 4px;
  --qm-radius-m: 8px;
  --qm-radius-l: 12px;
}

html,
body {
  min-width: var(--qm-layout-width);
  line-height: 1;
}

body,
ul,
ol,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
form,
fieldset,
legend,
input,
textarea,
button,
p,
blockquote,
th,
td,
pre,
xmp {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

caption,
th {
  text-align: inherit;
}

ul,
ol,
menu {
  list-style: none;
}

fieldset,
img {
  border: 0;
}

img,
object,
input,
textarea,
button,
select {
  vertical-align: middle;
}

article,
aside,
footer,
header,
section,
nav,
figure,
figcaption,
hgroup,
details,
menu {
  display: block;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "\0020";
}

textarea {
  overflow: auto;
  resize: vertical;
}

input,
textarea,
button,
select,
a {
  outline: 0 none;
  border: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
ins,
s,
u,
del {
  text-decoration: none;
}

sup,
sub {
  vertical-align: baseline;
}

svg:not(:root) {
  overflow: hidden;
}

a {
  text-decoration: none;
}

input::-ms-clear,
input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

* {
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

